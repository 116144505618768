<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :items="qualificationEvidence"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(document_link)="{ item }">
        <a :href="item.document_link" target="_blank" class="text-nowrap">{{ item.document_link }}</a>
      </template>

      <template #cell(created)="{ item }">
        <span class="text-nowrap">{{ item.created | diffForHumansMaxTwoWeeks }}</span>
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-inline-flex">
          <b-button
            v-b-tooltip.hover.top="'Update Qualification Evidence'"
            variant="flat-primary"
            class="py-0 btn-icon"
            @click="$emit('open-update-modal', item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Delete Qualification Evidence'"
            variant="flat-danger"
            class="py-0 btn-icon"
            @click="$emit('open-delete-modal', item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>
  </b-card>
</template>

<script>
import { BPagination, BTable, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'QualificationEvidenceTableView',
  directives: { 'b-tooltip': VBTooltip },
  components: {
    BTable,
    BPagination,
  },
  props: {
    qualificationEvidence: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'title', label: 'Title', sortable: true, tdClass: 'text-nowrap',
        },
        {
          key: 'document_id', label: 'Document ID', sortable: true,
        },
        {
          key: 'document_link', label: 'Document Link', sortable: true,
        },
        {
          key: 'document_date', label: 'Document Date', sortable: true,
        },
        {
          key: 'version', label: 'Version', sortable: false,
        },
        {
          key: 'created', label: 'Created', sortable: true,
        },
        {
          key: 'created_by', label: 'Created By', sortable: true,
        },
        {
          key: 'actions', label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_by',
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    qualificationEvidence(newVal) {
      this.totalRows = newVal.length
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-context.scss';
</style>
